import React from "react";

import { AxonContainer } from "@axontraining-sistemas/lib-common";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Heading,
  Box,
  Flex,
} from "@chakra-ui/react";

import Rows from "./Rows";

import { fechaAmigable } from "../../Services/UltilsService";

import CargaDatosDiaria from "../CargaDatosDiaria";

const Index = React.memo(({ datos, fetchIsLoading }) => {
  if (!datos) return null;

  if (fetchIsLoading) return null;

  return datos.fechas.map((fecha, i) => {
    const stats = datos.data[fecha].stats;
    return (
      <Box key={i} borderRadius="lg" bgColor="white" shadow="lg" mt="5vh !important">
        <Box p={4}>
          <Flex justifyContent="space-between">
            <Heading color="gray.600" size="h3" p={2}>
              Fecha: {fechaAmigable(fecha)}
            </Heading>
            <Heading color="gray.600" size="h5" p={2}>
              Datos: {datos.data[fecha].totalDatos}
            </Heading>
            <Box w="80%">
              <CargaDatosDiaria
                fecha={fecha}
                entrantes={stats[0].value}
                alumnos={stats[1].value}
                nuevos={stats[2].value}
                ventas={stats[3].value}
              />
            </Box>
          </Flex>
          <AxonContainer colorScheme="blue.500">
            <Table variant="striped" colorScheme="gray" size="sm">
              <Thead>
                <Tr>
                  <Th w="1%"></Th>
                  <Th color="blue.500" w="5%" textAlign="center">
                    Dia
                  </Th>
                  <Th color="blue.500" w="10%" textAlign="center">
                    Operador
                  </Th>
                  <Th color="blue.500" w="10%" textAlign="center">
                    Nombre
                  </Th>
                  <Th color="blue.500" w="15%" textAlign="center">
                    Número de WhatsApp
                  </Th>
                  <Th color="blue.500" w="5%" textAlign="center">
                    Email
                  </Th>
                  <Th color="blue.500" w="5%" textAlign="center">
                    País
                  </Th>
                  <Th color="blue.500" w="10%" textAlign="center">
                    Curso
                  </Th>
                  <Th color="blue.500" maxW="15%" textAlign="center">
                    Observación
                  </Th>
                  <Th color="blue.500" maxW="15%" textAlign="center">
                    Anuncio
                  </Th>
                  <Th color="blue.500" maxW="15%" textAlign="center">
                    Toques
                  </Th>
                  <Th color="blue.500" w="10%" textAlign="center">
                    Estado
                  </Th>
                  <Th color="blue.500" w="10%" textAlign="center">
                    Accion
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Rows fecha={fecha} datos={datos} />
              </Tbody>
            </Table>
          </AxonContainer>
        </Box>
      </Box>
    );
  });
});

export default Index;
