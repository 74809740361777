import React, { useContext, useState } from "react";

import { Td, Tr, Center, Tooltip, Flex, Box  } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import { ModalAxon } from "@axontraining-sistemas/lib-common";

import EmailViewer from "./EmailViewer";
import RowPais from "./RowPais";
import {
    MdLink,
    MdSearch,
    MdCardGiftcard,
    MdPeople,
    MdOutlineCreditCard
} from "react-icons/md";

import { SessionContext } from "../../Context/sessionContext";
import ActionButton from "../../Common/ActionButton/ActionButton";
import EditarDato from "../EditarDato";
import { generateLog } from "../../Services/UltilsService";
import CirculoEstados from "../../Common/CirculoEstados/CirculoEstados";
import DrawerManager from "../../Common/Drawer/DrawerManager";
import CheckBoxRow from "./CheckBoxRow";
import Historial from "../Historia/Historial";
import usePermisos from "../../Hooks/usePermisos";

import { SEARCH_ADMIN, SHOW_ANUNCIO } from "../../Constants/buscarDato";
import { fechaAmigable } from "../../Services/UltilsService";
import Pending from "./Pending";
import { ModalCarrera } from "./ModalCarrera";
import ModalPasarelas from "./ModalPasarelas";

const Rows = ({ fecha, datos }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const {
    session: { login, idContacto },
  } = useContext(SessionContext);

  const toast = useToast();

  const { rows } = datos.data[fecha];

  const { hasPermiso } = usePermisos();

  const isAdmin = hasPermiso(SEARCH_ADMIN);
  const creaPending = hasPermiso(390);
  const showAnuncio = hasPermiso(SHOW_ANUNCIO);

  const editResult = (result, editedData, resultMsg = null) => {
    toast({
      title: result === 200 ? "Dato editado!" : resultMsg,
      status: result === 200 ? "success" : "error",
      duration: 5000,
      isClosable: true,
    });

    if (result === 200) {
      const rowEditada = rows.find((row) => row.id == editedData.id);
      // Guardo log solo en caso de que el estado de la venta se haya modificado
      if (rowEditada.idEstadoVenta != editedData.idEstadoVenta) {
        generateLog("log_crm_estados", {
          idEstado: editedData.idEstadoVenta,
          vendedor: login,
          idVenta: editedData.idVenta,
          idServicio: editedData.idServicio,
        });
      }
    }
  };

  return rows.map((row, i) => {
    return (
      <Tr key={i}>
        <Td>{isAdmin && <CheckBoxRow idVenta={row.idVenta} />}</Td>
        <Td textAlign="center">{fecha?.slice(fecha.length - 2)}</Td>
        <Td textAlign="center">{row.responsable}</Td>
        <Td textAlign="center" >
          <Flex justifyContent="center" alignItems="center">
            <span>{`${row.nombre} ${row.apellido}`}</span>
            <Flex alignItems="center" marginLeft="8px">
              {row.esAlumno == 1 && (
                <Tooltip hasArrow  label='Es Cliente' bg='green.600' placement="right">
                  <Box cursor="pointer">
                    <MdPeople color="black" />
                  </Box>
                </Tooltip>
              )}
              {row.esFreemium == 1 && <span style={{ marginLeft: "4px" }}>(F)</span>}
            </Flex>
          </Flex>
        </Td>
        <Td textAlign="center">
          <a
            href={`https://api.whatsapp.com/send?phone=${row.celular}`}
            target="_blank"
          >
            {row.celular}
          </a>
        </Td>
        <Td textAlign="center">
          <EmailViewer
            email={row.email}
            showEmail={!!parseInt(row.showEmail)}
            isAdmin={true}
          />
        </Td>
        <Td>
          <RowPais
            nombrePais={row?.pais?.nombre}
            urlImage={row?.pais?.bandera}
          />
        </Td>
        <Td textAlign="center">{row.alias}</Td>
        {/* Alan Brito maxWidth... si te sabes una mejor, modificalo */}
        <Td isTruncated title={row.observaciones} style={{ maxWidth: "100px" }}>
          {row.observaciones}
        </Td>
        <Td
          textAlign="center"
          isTruncated
          title={row.anuncioAmigable}
          style={{ maxWidth: "100px" }}
        >
          {showAnuncio ? row.anuncioAmigable : "-"}
        </Td>
        <Td textAlign="center" title={row.intento}>
          {row.intento}
        </Td>
        <Td justifySelf="center">
          <Center>
            <CirculoEstados
              background={row.colorEstadoVenta}
              message={
                row.ventaProvisoriaFecha
                  ? `Fecha pago: ${fechaAmigable(row.ventaProvisoriaFecha)}`
                  : row.estadoVenta
              }
              m={0}
            />
          </Center>
        </Td>
        <Td>
          <Center>
            <EditarDato
              data={row}
              index={i}
              editResult={editResult}
              isDisabled={!!!parseInt(row.datoEditable)}
            />

            <ActionButton
              onClick={() => {
                console.log(row);
                window.open(
                  `https://${
                    window.location.hostname === "crm.axontraining.com"
                      ? "crm.axontraining.com"
                      : "dev-crm.axontraining.com"
                  }/generacion-link?params=` +
                    window.btoa(
                      unescape(encodeURIComponent(JSON.stringify(row)))
                    )
                );
              }}
              iconComponent={<MdLink size={25} color="206cc6" />}
            />

            {creaPending && (
              <Pending
                row={row}
                isDisabled={isDisabled}
                setIsDisabled={setIsDisabled}
              />
            )}

            <DrawerManager
              trigger={
                <ActionButton
                  iconComponent={<MdSearch size={25} color="206cc6" />}
                />
              }
              size="lg"
              title="Historia"
              component={
                <Historial idContacto={row.id} idVenta={row.idVenta} />
              }
            />

              {hasPermiso(510) && (
                  <ModalAxon
                      title={'Crear Pasarela'}
                      size="sm"
                      trigger={
                          <ActionButton
                              iconComponent={
                                  <MdOutlineCreditCard size={25} color={"206cc6"} />
                              }
                          />
                      }
                      render={({ onClose }) => (
                          <ModalPasarelas row={row} onClose={onClose} idContacto={idContacto} />
                      )}
                  />
              )}

            {hasPermiso(416) && row.ventaCarrera == 1 && (
              <ModalAxon
                title={`Crear dato ${
                  row.idServicio == 2 ? "DFOCO8M" : "FLA8M"
                }`}
                size="4xl"
                trigger={
                  <ActionButton
                    iconComponent={
                      <MdCardGiftcard size={25} color={"206cc6"} />
                    }
                  />
                }
                render={({ onClose }) => (
                  <ModalCarrera dato={row} login={login} onClose={onClose} />
                )}
              />
            )}
          </Center>
        </Td>
      </Tr>
    );
  });
};

export default Rows;
