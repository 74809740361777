import {getCookie} from "../../Services/AuthService";
import React, {useEffect, useState} from "react";
import axonApi from "../../Services/AxonApi";
import {Button} from "@chakra-ui/react";
import {useToast} from "@chakra-ui/toast";

const selectStyles = {
    container: {
        paddingBottom: "10px",
    },
    select: {
        padding: "8px 12px",
        fontSize: "16px",
        borderRadius: "8px",
        border: "1px solid #ccc",
        backgroundColor: "#fff",
        cursor: "pointer",
        outline: "none",
        transition: "border-color 0.3s ease-in-out",
        display: "flex",
        alignItems: "center",
        width: "95%",
        margin: 'auto'
    },
    option: {
        fontSize: "16px",
        padding: "10px",
    },
};

const buttonStyles = {
    container: {
        display: "flex",
        gap: "8px",
        justifyContent: "end",
        width: "100%",
        padding: "10px",
    }
}

const errorStyles = {
    errorMessage: {
        width: "92%",
        margin: "auto",
        fontSize: "12px",
        color: "red",
    }
}

export default function ModalPasarelas({ row, onClose, idContacto }) {
    const token = getCookie("token_empleados");
    const toast = useToast();

    const [pasarelas, setPasarelas] = useState([]);
    const [selectedPasarela, setSelectedPasarela] = useState('');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const getPasarelasByCountry = async () => {
        setLoading(true)
        try {
            const response = await axonApi.get('internos/crm/ventas/dato/pasarela', {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })

            if(response?.data?.data) {
                const pasarelas = response.data.data

                const pasarelasByCountry = pasarelas.filter((pasarela) => {
                    return pasarela.in.includes(Number(row.pais.id));
                });

                setPasarelas(pasarelasByCountry)
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const createPasarela = async () => {
        setLoading(true);

        const pasarelaData = {
            idContacto: idContacto,
            idVenta: row.idVenta,
            idPasarela: selectedPasarela,
        }

        if(!pasarelaData.idPasarela) {
            setError(true);
            setLoading(false);
            return;
        } else {
            setError(false);
        }

        try {
            const response = await axonApi.post('internos/crm/ventas/dato/pasarela', pasarelaData, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })

            onClose()

            toast({
                title: response.data.code === 200 ? "Pasarela creada!" : 'Error al crear pasarela',
                status: response.data.code === 200 ? "success" : "error",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const handleChangePasarela = (e) => {
        const idPasarela = e.target.value;
        setSelectedPasarela(idPasarela);
    };

    useEffect(() => {
        (async () => {
            await getPasarelasByCountry();
        })()
    }, []);

    return (
        <div style={selectStyles.container}>
            <select style={selectStyles.select} onChange={handleChangePasarela} value={selectedPasarela}>
                <option value="" disabled>Selecciona una opción</option>
                {pasarelas.map((pasarela) => (
                    <option key={pasarela.idPasarela} value={pasarela.idPasarela} style={selectStyles.option}>{pasarela.nombre}</option>
                ))}
            </select>

            <div hidden={!error} style={errorStyles.errorMessage}>Selecciona una opción válida</div>

            <div style={buttonStyles.container}>
                <Button onClick={onClose} isDisabled={loading}>
                    Cancelar
                </Button>
                <Button
                    colorScheme={"blue"}
                    isLoading={loading}
                    onClick={createPasarela}
                >
                    Crear Pasarela
                </Button>
            </div>
        </div>
    );
}

