import { normalize, schema } from "normalizr";
import axonApi from "./AxonApi";

const normaliarJsonPermisos = (array) => {
  const permiso = new schema.Entity(
    "permisos",
    {},
    { idAttribute: "idaction" }
  );
  return normalize(array, [permiso]).entities.permisos;
};

export const getPermisosNormalizados = async (userId) => {
  if (userId) {
    const res = await axonApi.get(
      `/usuarios/${userId}/acciones`
    );

    return normaliarJsonPermisos(res.data);
  }
  return {};
};
